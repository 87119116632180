import React, { useRef } from 'react'
import emailjs from '@emailjs/browser';


function Contact() {
     const form = useRef();

     const sendEmail = (e) => {
          e.preventDefault();
          emailjs.sendForm('service_60i9wgj', 'template_st0vm9g', form.current, '4op9ki8PTaDE0yVdo')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        };
  return (
    // <!-- CONTACT SECTION STARTS -->
    <section id="contact">
         <div className="contact-heading">
              <h2>Have an idea for a Blockchain project?</h2>
              <p>Let’s discuss it!
                   You will receive a reply within 24 hours.</p>
         </div>

         <form ref={form} onSubmit={sendEmail} role="form" method="post" id="reused_form">
             <div className="contact-form">
                  <div className="contact-row">
                       <input type="text" id="name" name="name" placeholder="*Name" required/>
                  </div>
                  <div className="contact-row">
                       <input type="text" id="email" name="email" placeholder="*Your Work Email" required />
                       <input type="tel" id="phone" name="phone" placeholder="Phone"/>
                  </div>
                  <div className="contact-row">
                       <textarea name="message" id="comments" cols="30" rows="10"></textarea>
                  </div>

                  <div className="contact-form-cta">
                       <button type="submit" id="submitButton">
                            Request Consultation
                       </button>
                  </div>
             </div>
             </form>
             <div id="success_message"> <h3>Posted your feedback successfully!</h3> </div>
             <div id="error_message">Sorry there was an error sending your form. </div>
    </section>
)
}

export default Contact