import React from 'react'
import "../css/main.css"
import banner1 from "../images/banner.mp4"
import image1 from "../images/image1.png"
import twitter from "../images/twitter-footer.svg"
import linkedin from "../images/linkedin.svg"
import github from "../images/github.svg"
import discord from "../images/discord.svg"
function Banner() {
  return (
    <section id="home" className="hero-section">
    <div className="section-wrapper">
         <div className="hero-video" style={{marginTop:150}}>
          <img className='hero-image' src={image1}/>
              {/* <video autoplay muted loop>  */}
                               {/* <source src={banner1}/> */}
                          {/* </video>  */}
         </div>
         <div className="hero-content">
              <div className="we-do" data-aos="fade-up">
                {/* <span className="we-web3">We do</span> */}
               <span
                        className="web3-blink"> Web3</span>
              </div>
              <div className="hero-heading" data-aos="fade-up">
                   {/* <!-- <span className="hero-color">NXT WEB3</span> ---> */}
                   <span className="hero-color logo">
                    <b> 
                    {/* <span>NXT3</span> */}
                     {/* enables */}
                   </b>
                   </span>
                   Decentrazlize, Traditional Architecture with blockchain.
              </div>
              <div>
                   <a href="#contact" className="primary-cta" data-aos="zoom-in">Get Started</a>
              </div>
         </div>
    </div>
    {/* <footer className="footer-section-effect"> */}
         {/* <!-- Back to top button --> */}
         {/* <a id="backToTop"></a> */}
         <div className='banner-social-links'>
              <a href="https://twitter.com/kumailnaqvi616" className="modal-trigger" data-modal="modal-name">
                <img src={twitter}/></a>
         {/* </div>
         <div> */}
              <a href="https://linkedin.com/in/blockchain-kumail-ali" className="modal-trigger" data-modal="modal-name">
                <img src={linkedin}/></a>
         {/* </div>
         <div> */}
              <a href="https://github.com/kumailnaqvi354" className="modal-trigger" data-modal="modal-name">
                <img src={github}/></a>
         {/* </div>
         <div> */}
              <a href="https://discord.com/users/kumail#0571" className="modal-trigger" data-modal="modal-name">
                <img src={discord}/></a>
         </div>
    {/* </footer>z */}

</section>
  )
}

export default Banner