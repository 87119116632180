import React from "react";
import resume from "../Kumail-Ali-Resume.pdf"

function About() {
  return (
    // <!-- ABOUT SECTION STARTS -->
    <section id="about">
      <div className="section-wrapper">
        <div className="heading-container">
          <h2 className="mb-5">ABOUT</h2>
          <p className="center-para my-1">
            Experienced Blockchain Developer with a demonstrated history of
            working in the computer software industry. Skilled in Smart Contract
            Development, DeFi, NFT, Financial Accounting, and Algorithm
            Analysis. Developed Dapp, Staking, NFT, and Minting platform, now
            looking at solutions like Solana to get hands-on experience of
            Non-EVM based architecture. A Strong engineering professional with a
            Bachelor's degree focused in Computer Software Engineering from Iqra
            University (Official).
          </p>
         
         

          {/* <p className="center-para my-1">Creativity & excellence in the
                        web3 space, we created
                         To help the next generation of thinkers & doers realise their blockchain related
                        dreams.</p> */}
          {/* <p className="center-para my-1">
                        We can either help you with a defined project or work as an augmented arm of your company
                        – your project your
                        set-up.
                        We are not just your anon devs. We are a completely doxxed company with our base in the
                        UK.</p> */}
          {/* <p className="center-para my-1">NXT3 is a brand of NXT Labs Limited, registered in England and
                        Wales with reg no. 13175546.,
                        with over 50 people in our team with a large group working solely on web3.</p> */}
          {/* <p className="center-para my-1">To us, being a part of the next generation of ground-breaking tech
                        and working with the
                        smartest minds in the world is far more important than charging exorbitant fees.</p> */}
          {/* <p className="center-para my-1">So reach out for a quote and we’re sure we’ll surprise you!</p> */}
        </div>
      <a className="contact-heading h2" style={{textDecoration: "none"}} href={resume} target="_blank"><h2>Resume</h2></a>
      </div>

    </section>
  );
}

export default About;
