import React from 'react'
import twitter from "../images/twitter-footer.svg"
import linkedin from "../images/linkedin.svg"
import github from "../images/github.svg"
import discord from "../images/discord.svg"

function Footer() {
  return (
    // <!-- FOOTER STARTS -->
    <>

    <footer className="footer-section-effect">
    <div style={{paddingRight:"5px", paddingLeft:"5px"}}> 

         
              <a href="https://twitter.com/kumailnaqvi616" data-modal="modal-name">
                <img src={twitter}/></a>
        
              <a href="https://linkedin.com/in/blockchain-kumail-ali" data-modal="modal-name">
                <img src={linkedin}/></a>
         
              <a href="https://github.com/kumailnaqvi354" data-modal="modal-name">
                <img src={github}/></a>
         
              <a href="https://discord.com/users/kumail#0571" data-modal="modal-name">
                <img src={discord}/></a>
         
    </div>

         {/* <!-- Back to top button --> */}
         <a id="backToTop"></a>
         <div><a href="mailto:komailnaqvi616@gmail.com" style={{fontSize:"11px"}}>komailnaqvi616@gmail.com</a></div>
         {/* <div>
              <span>Powered by</span>: 
              <span><a href="#" target="_blank"><img src=""/>KUMAIL</a></span>
         </div> */}
         <div className="last-foot"> Copyright © {(new Date().getFullYear())} KUMAIL.</div>
    </footer>
              </>

  )
}

export default Footer