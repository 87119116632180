import React, { useEffect } from "react";
import wgmi from "../images/wgmi-project.png";
import mysteryshells from "../images/mystery-shell-project.svg";
import badassWomen from "../images/badassWomen-project.png";
import ccw from "../images/cryptoCultureWar-project.png";
import socal from "../images/socal.png";
import modihost from "../images/modihost.jpg";
import wagmigame from "../images/wagmi-game.png";
import tfl from "../images/TFL.png";
import cyberbattle from "../images/Cyber-Battle.png";
import verticalcrypto from "../images/verticalCrypto.svg";

import Aos from "aos";

function Work() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    // <!-- WORK SECTION STARTS -->
    <section id="work" className="work-section-effect">
      <div className="heading-container">
        <h2>SOME RECENT WORK</h2>
      </div>

      <div className="work-container">
        {/* <!-- work row 1 --> */}
        <div className="work-row">
          {/* <!-- Cyber Battle --> */}
          <div
            className="work-col gradient-ten"
            data-aos="fade-up"
            data-aos-delay="260"
            data-aos-duration="1500"
          >
            <a href="https://dev.cyberbattles.gg/" target="_blank">
              <h3 className="project-title">Cyber Battle</h3>
              <img src={cyberbattle} className="w-100" alt="socal" />

              <div className="work-box">
                <span>Game Based Streaming Platform for gamers, where users are able to stake and Win rewards in crypto as well as in Fiat Currency. Worked on Crypto Payments and automating Crypto Withdrawals from users. Worked with Team to provide optimal solution on cryto payments and purchase of goods on the application</span>
                <div className="tags">
                  <span>Ethereum</span>
                  <span>Crypto Payments</span>
                  <span>DeFi</span>
                  <span>UNISWAP</span>
                  <span>UNISWAP SDK</span>
                  <span>ETH</span>
                  <span>USDC</span>
                  <span>Etherjs</span>
                  <span>NEXTJS</span>
                  <span>API Development</span>
                  <span>Nodejs</span>
                </div>
                <div className="work-cta-row">
                  {/* <!-- <a href="#" className="outline-cta">Learn More</a> -->
                                       <!-- <a href="#" className="primary-cta">Preview</a> --> */}
                </div>
              </div>
            </a>
          </div>

          {/* <!-- WAGMI  --> */}
          <div
            className="work-col gradient-seven"
            data-aos="fade-up"
            data-aos-delay="260"
            data-aos-duration="1500"
          >
            <a href="https://www.wagmigame.io/" target="_blank">
              <h3 className="project-title">Wagmi Game </h3>
              <img src={wagmigame} alt="wagmi game" />
            </a>

            <div className="work-box">
              <span>Play to Earn Game, Worked on Smart Contract Development, for users to sell pusrchase there game pack in the game</span>
              <div className="tags">
                <span>Binance Smart Chain</span>
                <span>Genesis Contract</span>
                <span>Game </span>
                <span>ERC721</span>
                <span>NFT</span>
                <span>Angular</span>
                <span>Node JS</span>
              </div>

              <div className="work-cta-row">
                {/* <!-- <a href="#" className="outline-cta">Learn More</a> -->
                                  <!-- <a href="#" className="primary-cta">Preview</a> --> */}
              </div>
            </div>
          </div>

          {/* <!-- TFL  --> */}
          <div
            className="work-col gradient-seven"
            data-aos="fade-up"
            data-aos-delay="260"
            data-aos-duration="1500"
          >
            <a href="https://www.playtaunt.io/" target="_blank">
              <h3 className="project-title">Taunt Fighting League </h3>
              <img src={tfl} alt="taunt" />
            </a>

            <div className="work-box">
              <span>Play to Earn Game, Worked on Smart Contract Development, for users to sell pusrchase there game pack in the game</span>

              <div className="tags">
                <span>Ethereum Blockchain</span>
                <span>Smart Contract</span>
                <span>ERC721</span>
                <span>NFT</span>
                <span>Cryptocurrency</span>
                <span>React JS</span>
                <span>Node JS</span>
                <span>Unit Testing</span>
                <span>Hardhat</span>

              </div>

              <div className="work-cta-row">
                {/* <!-- <a href="#" className="outline-cta">Learn More</a> -->
                                  <!-- <a href="#" className="primary-cta">Preview</a> --> */}
              </div>
            </div>
          </div>


          {/* <!-- SOCAL --> */}
          <div
            className="work-col gradient-ten"
            data-aos="fade-up"
            data-aos-delay="260"
            data-aos-duration="1500"
          >
            <a href="https://socaltoken.io/" target="_blank">
              <h3 className="project-title">SOCAL STAKING</h3>
              <img src={socal} className="w-100" alt="socal" />

              <div className="work-box">
                <span>An NFT Marketplace and Staking Platform. Worked on Staking Smart Contract, with APY of upto 300%. </span>
                <div className="tags">
                  <span>Binance Smart Chain</span>
                  <span>Smart Contract</span>
                  <span>Staking</span>
                  <span>Etherjs</span>
                  <span>Hardhat</span>
                  <span>Unit Testing</span>
                  <span>Mocha Chai</span>
                  <span>NEXTJS</span>
                  <span>Smart Contract Integration</span>
                </div>
                <div className="work-cta-row">
                  {/* <!-- <a href="#" className="outline-cta">Learn More</a> -->
                                       <!-- <a href="#" className="primary-cta">Preview</a> --> */}
                </div>
              </div>
            </a>
          </div>
          {/* <!-- Vertical Auction Crypto  --> */}
          <div
            className="work-col gradient-ten"
            data-aos="fade-up"
            data-aos-delay="260"
            data-aos-duration="1500"
          >
            <a href="https://auction.verticalcrypto.art/" target="_blank">
              <h3 className="project-title">Vertical Crypto</h3>
              <img src={verticalcrypto} className="w-100" alt="socal" />

              <div className="work-box">
                <span>NFT Marketplace for Artists and Designers, where they are able to auction and sell there artwork. Seller are able to list there NFT from other Smart Contracts and also they can create new from current marketplace.</span>
                <div className="tags">
                  <span>Ethereum</span>
                  <span>Smart Contract</span>
                  <span>ERC721</span>
                  <span>NFT</span>
                  <span>Marketplace</span>
                  <span>NFT Auction</span>
                  <span>Smart Contract Integration</span>
                  <span>Web3js</span>
                  <span>Hygraph</span>
                  <span>ReactJs</span>
                  <span>Nodejs</span>
                  <span>API</span>
                </div>
                <div className="work-cta-row">
                  {/* <!-- <a href="#" className="outline-cta">Learn More</a> -->
                                       <!-- <a href="#" className="primary-cta">Preview</a> --> */}
                </div>
              </div>
            </a>
          </div>

          {/* <!-- WGMI --> */}
          <div
            className="work-col gradient-one"
            data-aos="fade-up"
            data-aos-delay="260"
            data-aos-duration="1500"
          >
            <a href="https://wgmi.io/" target="_blank">
              <h3 className="project-title">WGMI</h3>
              <img src={wgmi} alt="WGMI" />

              <div className="work-box">
                {/* <!-- <div>
                                       <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum is simply.</p>
                                  </div> --> */}
                <span>Worked on Airdrop NFT Smart Contract with Whitelist minting functionality. user were able to enter into the premium section of the application using airdroped NFT. </span>
                <div className="tags">
                  <span>Ethereum Blockchain</span>
                  <span>Smart Contract</span>
                  <span>ERC721</span>
                  <span>NFT</span>
                  <span>Airdrop</span>
                  <span>Optimization</span>
                  <span>Web3js</span>
                  <span>Smart Contract Integration</span>
                  <span>Angular JS</span>
                </div>

                <div className="work-cta-row">
                  {/* <!-- <a href="#" className="outline-cta">Learn More</a> -->
                                       <!-- <a href="#" className="primary-cta">Preview</a> --> */}
                </div>
              </div>
            </a>
          </div>

          {/* <!-- Modihost/HMS  --> */}
          <div
            className="work-col  gradient-two"
            data-aos="fade-up"
            data-aos-delay="230"
            data-aos-duration="1500"
          >
            <a href="https://twitter.com/modihost" target="_blank">
              <h3 className="project-title">Modihost</h3>
              <img src={modihost} className="Modihost" />

              <div className="work-box">
                <span>EOS blockchain based DeFi application, Worked on Problem Solving, Research and providing optimal solution implementation of the application.</span>
                <div className="tags">
                  <span>EOS Blockchain</span>
                  <span>R&D</span>
                  <span>Smart Contract</span>
                  <span>DeFi</span>
                  <span>Staking</span>
                  <span>Pool Rewards</span>
                  <span>C++</span>
                  <span>API</span>
                  <span>javascript</span>
                  <span>React JS</span>
                  <span>Node JS</span>
                </div>

                <div className="work-cta-row">
                  {/* <!-- <a href="#" className="outline-cta">Learn More</a> -->
                                       <!-- <a href="#" className="primary-cta">Preview</a> --> */}
                </div>
              </div>
            </a>
          </div>

          {/* <!-- MYSTERY SHELL --> */}
          <div
            className="work-col  gradient-two"
            data-aos="fade-up"
            data-aos-delay="230"
            data-aos-duration="1500"
          >
            <a href="https://mysteryshells.io/" target="_blank">
              <h3 className="project-title">Mystery Shell</h3>
              <img src={mysteryshells} className="Mystery Shell Project" />

              <div className="work-box">
                <span> A Raffle Based NFT Rewarding game. User can win NFT by simply spinnig of the wheel. Users were able to increase number chances to win in raffle by burning a NFT</span>
                <div className="tags">
                  <span>Ethereum Blockchain</span>
                  <span>Smart Contract</span>
                  <span>ERC721</span>
                  <span>NFT</span>
                  <span>NFT Reward</span>
                  <span>Moralis</span>
                  <span>API Development</span>
                  <span>React JS</span>
                  <span>Node JS</span>
                </div>

                <div className="work-cta-row">
                  {/* <!-- <a href="#" className="outline-cta">Learn More</a> -->
                                       <!-- <a href="#" className="primary-cta">Preview</a> --> */}
                </div>
              </div>
            </a>
          </div>


          {/* <!-- CRYPTO CULTURE WAR --> */}
          <div
            className="work-col gradient-three"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-duration="1500"
          >
            <a
              href="https://www.nftculture.com/nft-projects/crypto-wars/"
              target="_blank"
            >
              <h3 className="project-title">Crypto Culture War</h3>
              <img src={ccw} alt="Crypto Culture War" />
            </a>

            <div className="work-box">
              <span>Minting website, worked on development of Smart Contract with different phases of minting.</span>

              <div className="tags">
                <span>Ethereum Blockchain</span>
                <span>Smart Contract</span>
                <span>NFT</span>
                <span>IPFS</span>
                <span>Front End</span>
                <span>Web3js</span>
                <span>Web3 Integration</span>
              </div>

              <div className="work-cta-row">
                {/* <!-- <a href="#" className="outline-cta">Learn More</a> -->
                                  <!-- <a href="#" className="primary-cta">Preview</a> --> */}
              </div>
            </div>
          </div>

          {/* <!-- BADASS WOMEN --> */}
          <div
            className="work-col gradient-four"
            data-aos="fade-up"
            data-aos-delay="260"
            data-aos-duration="1500"
          >
            <a href="https://twitter.com/badasswomennft1" target="_blank">
              <h3 className="project-title">Badass Women</h3>
              <img src={badassWomen} className="w-100" alt="Bad Ass Women" />

              <div className="work-box">
                <span>Minting website, worked on development of Smart Contract with different phases of minting.</span>

                <div className="tags">
                  <span>Ethereum</span>
                  <span>Smart Contract</span>
                  <span>ERC721</span>
                  <span>NFT</span>
                  <span>Unit Testing</span>
                  <span>Hardhat</span>


                </div>
                <div className="work-cta-row">
                  {/* <!-- <a href="#" className="outline-cta">Learn More</a> -->
                                       <!-- <a href="#" className="primary-cta">Preview</a> --> */}
                </div>
              </div>
            </a>
          </div>

        </div>
      </div>
    </section>
  );
}

export default Work;
