import React from 'react'
import solidity from '../images/solidity-icon.png';
import nftweb from '../images/nft-web-icon.png'
import consultation from '../images/discord-moderation-icon.png' 
import audits from '../images/smart-contract-audit-icon.png'
import artwork from '../images/nft-artwork-icon.png'
import infrastructure from '../images/infrastructure-icon.png'
function Expertise() {
  return (
    // <!-- BLOCKCHAIN EXPERTISE SECTION STARTS -->
    <section id="expertise">
         <div className="section-wrapper">
              <div className="heading-container">
                   <h2>BLOCKCHAIN EXPERTISE</h2>
                   <p className="center-para">
                        Blockchain Expert and understand the entire cycle of what needs to be done for a
                        successful project. From the artwork, to Consulatation, to smart contracts to the
                        entire infrastructure itself. Contact us today for all your blockchain related needs.
                   </p>
              </div>
              <div className="nxt-row">
                   <div className="nxt-col">
                        <div className="expertise-box to-right">
                             <div className="icon"><img src={solidity} alt="" className="img-fluid"/></div>
                             <div className="content">
                                  <h3>Smart Contract Development (Solidity Development)</h3>
                                  <a href="#" data-toggle="modal" data-target="#smart-contract">learn more</a>
                             </div>
                        </div>
                        <div className="expertise-box">
                             <div className="icon"><img src={nftweb} alt="" className="img-fluid"/></div>
                             <div className="content">
                                  <h3>Minting Pages</h3>
                                  <a href="#" data-toggle="modal" data-target="#nft-webdesign">learn more</a>
                             </div>
                        </div>
                        <div className="expertise-box to-right">
                             <div className="icon">
                              <img src={consultation} alt=""
                                       className="img-fluid"/></div>
                             <div className="content">
                                  <h3>Consultation</h3>
                                  <a href="#" data-toggle="modal" data-target="#community">learn more</a>
                             </div>
                        </div>
                   </div>
                   <div className="nxt-col centered">
                        <div className="animated-border">
                             <div className="nxt-three">
                                  <h2>KUMAIL</h2>
                             </div>
                        </div>
                   </div>
                   <div className="nxt-col">
                        <div className="expertise-box to-left">
                             <div className="icon"><img src={audits} alt=""
                                       className="img-fluid"/></div>
                             <div className="content">
                                  <h3> Smart Contract Audits</h3>
                                  <a href="#" data-toggle="modal" data-target="#smart-audit">learn more</a>
                             </div>
                        </div>
                        <div className="expertise-box">
                             <div className="icon"><img src={artwork} alt="" className="img-fluid" />
                             </div>
                             <div className="content">
                                  <h3>NFT Artwork</h3>
                                  <a href="#" data-toggle="modal" data-target="#nft-artwork">learn more</a>
                             </div>

                        </div>
                        <div className="expertise-box to-left">
                             <div className="icon"><img src={infrastructure} alt="" className="img-fluid" />
                             </div>
                             <div className="content">
                                  <h3>Infrastructure</h3>
                                  <a href="#" data-toggle="modal" data-target="#infrastructure">learn more</a>
                             </div>
                        </div>
                   </div>
              </div>
              
         </div>
         <div className='tag-skills'>

        
         <div className="work-box">
                <div className="tags">
                  <span>Ethereum</span> 
                  <span>Polygon</span> 
                  <span>Binance</span> 
                  <span>Solana(Beginner)</span> 
                  <span>Solidity</span> 
                  <span>GoLang</span> 
                  <span>Yull/Yull++</span> 
                  <span>Rust (Beginner)</span> 
                  <span>JavaScript</span> 
                  <span>Typescript</span> 
                  <span>Smart Contract</span>
                  <span>Staking</span> 
                  <span>Yield Farming</span> 
                  <span>DeFi</span> 
                  <span>NFT</span>
                  <span>Marketplace</span>
                  <span>Smart Contract Integration</span>
                  <span>Etherjs</span>
                  <span>Web3js</span>
                  <span>Solana/Web3js</span>
                  <span>Architect</span>
                  <span>Auditing</span>
                  <span>ReactJs</span>  
                  <span>Nextjs</span>  
                  <span>Nestjs</span>  
                  <span>Expressjs</span>  
                  <span>Nodejs</span>
                  <span>API</span>
                </div>
                </div>
                </div>
    </section>


    )
}

export default Expertise