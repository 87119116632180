// import logo from "./logo.svg";
import "./App.css";
import Header from "./components/Header";
import Banner from "./components/Banner";
import Expertise from "./components/Expertise";
import About from "./components/About";
import Work from "./components/Work";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import $ from "jquery"
import TweenMax from "gsap";
import { useEffect } from "react";

function App() {
  
  useEffect(() => {
    var cursor = $(".cursor"),
    follower = $(".cursor-follower");
  
  var posX = 0,
    posY = 0;
  
  var mouseX = 0,
    mouseY = 0;
  
  TweenMax.to({}, 0.016, {
    repeat: -1,
    onRepeat: function () {
         posX += (mouseX - posX) / 9;
         posY += (mouseY - posY) / 9;
  
         TweenMax.set(follower, {
              css: {
                   left: posX - 12,
                   top: posY - 12
              }
         });
  
         TweenMax.set(cursor, {
              css: {
                   left: mouseX,
                   top: mouseY
              }
         });
    }
  });
  
  $(document).on("mousemove", function (e) {
    mouseX = e.pageX;
    mouseY = e.pageY;
  });
  
  }, [])
  

  return (
    <div className="App">
      <Header />
      <Banner />
      <Expertise/>
      <About/>
      <Work/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
