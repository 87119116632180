import React, { useEffect } from 'react'
import "../css/main.css"
import logo from "../images/logo.png"
import MmenuLight from "mmenu-light"
import $ from "jquery"

function Header() {
     useEffect(() => {
       

var menu = new MmenuLight(document.querySelector("#mobile_menu"), "all");

var navigator = menu.navigation({
 // selectedClass: 'Selected',
 // slidingSubmenus: true,
 theme: 'dark',
 title: ''
});

var drawer = menu.offcanvas({
 position: 'right'
});

//	Open the menu.
document
 .querySelector('a[id="menuToggle"]')
 .addEventListener("click", (evnt) => {
  evnt.preventDefault();
  drawer.open();
  // $('html, body').animate({
  //   scrollTop: 0
  //  }, 0);
 });

// menu iteam click scroll and offset space set
 $('a[href^="#"]').on('click', function(event) {
  let currentDiv = this.getAttribute ('href');
  let add = 0;
  
  var target = $(this.getAttribute ('href') );
  if (target.length) {
    event.preventDefault();

    if(currentDiv === '#work'){
      add = 90;
    }else{
      add = 50;
    }

    $('html, body').stop().animate( {
      scrollTop: target.offset().top + -(add)
    }, 1000); 
  }
});


 //	Close the menu on click of any mobile navigation link
document.querySelectorAll('nav#mobile_menu a').forEach(li => li.addEventListener('click', function(e){
  $(document).on("click", 'a[href^="#"]', function (event) {
    event.preventDefault();
    // $("html, body").animate(
    //   {
    //     scrollTop: 0
    //   },
    //   0
    // );
  });
  drawer.close();
}));

// close menu on the click of close button
let closeButton = document.getElementById('closeBtn');
//console.log(closeButton)
closeButton.addEventListener('click', function(){
  drawer.close();
});


$(document).on('click', 'a[href^="#"]', function (event) {
 event.preventDefault();
//  $('html, body').animate({
//   scrollTop: $($.attr(this, 'href')).offset().top + -86
//  }, 1000);
});
     }, [])
     
  return (

    <header className="header">
    <div className="header-row">
         {/* <!-- desktop logo --> */}
         <div>
              {/* <img src={logo}/> */}
              <h1 style={{fontSize:"30px"}}> Kumail Ali</h1>
         </div>

         {/* <!-- desktop menu --> */}
         <div id="d-menu">
              <ul className="desktop-menu">
                   <li><a href="#home" className="menu-active">Home</a></li>
                   <li><a href="#expertise">Expertise</a></li>
                   <li><a href="#about">About</a></li>
                   <li><a href="#work">Work</a></li>
                   <li><a href="#contact" className="contact-cta">Contact us</a></li>
              </ul>
         </div>
    </div>
    <a id="menuToggle" className="toggler">
          <input type="checkbox" />
          <span></span>
          <span></span>
          <span></span>
     </a>
     {/* <!-- mobile navigation --> */}
     <nav id="mobile_menu">
          <button id="closeBtn">X</button>
          <ul>
               <li><a href="#home" className="menu-active nav-link">Home</a></li>
               <li><a href="#expertise" className="nav-link">Expertise</a></li>
               <li><a href="#about" className="nav-link">About</a></li>
               <li><a href="#work" className="nav-link">Work</a></li>
               <li><a href="#contact" className="nav-link contact-cta">Contact us</a></li>
          </ul>
     </nav>
     {/* <!-- custom cursor --> */}
     <div className="cursor"></div>
     <div className="cursor-follower"></div>
     {/* <!-- MAIN WRAPPER --> */}
     <div className="main-wrapper">
          <div className="noise__container">
               <div className="noise"></div>
          </div>
          </div>
</header>
  )
}

export default Header